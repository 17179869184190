var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"success","outlined":"","small":""},on:{"click":function($event){return _vm.download_excel()}}},[_vm._v("ดาวน์โหลด "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-download")])],1),_c('div',{staticClass:"text-h4 primary--text"},[_vm._v("รายงานสรุปยอด ค่าใช้จ่าย")]),_c('v-toolbar',{staticClass:"my-5 pt-3 pb-15",attrs:{"dense":"","flat":"","rounded":"","outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"label":"ชื่อผู้จำหน่าย","items":_vm.dealer_list,"item-text":"dealer_name","item-value":"dealer_name","outlined":"","rounded":"","dense":"","hide-details":"","autocomplete":"off"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dealer_name))]}}]),model:{value:(_vm.filter.dealer_name),callback:function ($$v) {_vm.$set(_vm.filter, "dealer_name", $$v)},expression:"filter.dealer_name"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"ช่วงเวลา","items":_vm.timeRangeTypes,"item-value":"value","item-text":"text","outlined":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.timeRangeType),callback:function ($$v) {_vm.timeRangeType=$$v},expression:"timeRangeType"}})],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.timeRangeType == 1)?_c('DatePicker',{attrs:{"label":"วันจดทะเบียน","value":_vm.filter.start_date,"hideDetails":"","outlined":"","rounded":"","dense":""},on:{"onChange":function (val) { return (_vm.filter.start_date = val); }}}):(_vm.timeRangeType == 2)?_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.filter.start_date ? _vm.getMonth(_vm.filter.start_date) :'',"label":"เดือน","prepend-icon":"mdi-calendar","hideDetails":"","outlined":"","rounded":"","dense":"","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":"","locale":"th"},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.onSaveMonth(_vm.month)}}},[_vm._v("OK")])],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.timeRangeType == 1)?_c('DatePicker',{attrs:{"label":"ถึงวันที่","value":_vm.filter.end_date,"hideDetails":"","outlined":"","rounded":"","dense":""},on:{"onChange":function (val) { return (_vm.filter.end_date = val); }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.getExpenseData()}}},[_vm._v("แสดงรายงาน "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-magnify")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$table_loading_text,"sort-by":"calories","page":_vm.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":"","item-key":"_id"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.date_of_issue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_of_issue)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.price,'0,0.00'))+" ")]}},{key:"item.vat_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.vat_price,'0,0.00'))+" ")]}},{key:"item.net_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.net_price,'0,0.00'))+" ")]}},{key:"item.payment_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment_type == 1 ? "เงินสด" : "")+" "+_vm._s(item.payment_type == 2 ? "โอน" : "")+" "+_vm._s(item.payment_type == 3 ? "บัตรเครดิต" : "")+" ")]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment_date)+" ")]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }