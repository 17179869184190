<template>
  <div class="px-10">
    <v-btn class="float-right" color="success" outlined small @click="download_excel()">ดาวน์โหลด <v-icon right>mdi-download</v-icon></v-btn>
    <div class="text-h4 primary--text">รายงานสรุปยอด ค่าใช้จ่าย</div>
    <v-toolbar dense flat rounded outlined class="my-5 pt-3 pb-15">
      <v-row>
      
        <v-col cols="2">
          <v-autocomplete
            label="ชื่อผู้จำหน่าย" 
            :items="dealer_list"
            item-text="dealer_name"
            item-value="dealer_name"
            outlined
            rounded
            dense
            hide-details 
            autocomplete="off"
            v-model="filter.dealer_name"
          ><template slot="item" slot-scope="{ item }"> {{item.dealer_name}}</template>
          </v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-select 
            label="ช่วงเวลา" 
            v-model="timeRangeType" 
            :items="timeRangeTypes"
            item-value="value"
            item-text="text"
            outlined 
            rounded 
            dense 
            hide-details>
          </v-select>
        </v-col>

        <v-col cols="2">
          <DatePicker 
              v-if="timeRangeType == 1"  
              label="วันจดทะเบียน" 
              :value="filter.start_date" 
              @onChange="(val) => (filter.start_date = val)" 
              hideDetails 
              outlined 
              rounded 
              dense
          />

          <v-menu v-else-if="timeRangeType == 2"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field 
                :value="filter.start_date ? getMonth(filter.start_date) :''" 
                label="เดือน" 
                prepend-icon="mdi-calendar" 
                v-bind="attrs" 
                v-on="on" 
                hideDetails 
                outlined 
                rounded 
                dense 
                readonly>
              </v-text-field>
            </template>
            <v-date-picker 
              v-model="month" 
              type="month" 
              no-title 
              scrollable 
              locale="th">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="onSaveMonth(month)">OK</v-btn>
            </v-date-picker>
          </v-menu>

        </v-col>

        <v-col cols="2">
          <DatePicker 
            v-if="timeRangeType == 1"  
            label="ถึงวันที่" 
            :value="filter.end_date" 
            @onChange="(val) => (filter.end_date = val)" 
            hideDetails 
            outlined 
            rounded 
            dense
          />
        </v-col>

        <v-col cols="2"></v-col>

        <v-col cols="2">
          <v-btn color="primary" small class="float-right" @click="getExpenseData()" >แสดงรายงาน <v-icon right>mdi-magnify</v-icon></v-btn>
        </v-col>

      </v-row>
    </v-toolbar>

    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :search="search"
      :loading="loading"
      :loading-text="$table_loading_text"
      sort-by="calories"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
    >
      <template v-slot:[`item.date_of_issue`]="{ item }">
        {{item.date_of_issue}}
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{item.price | numeral('0,0.00')}}
      </template>
      <template v-slot:[`item.vat_price`]="{ item }">
        {{item.vat_price | numeral('0,0.00')}}
      </template>
      <template v-slot:[`item.net_price`]="{ item }">
        {{item.net_price | numeral('0,0.00')}}
      </template>
      <template v-slot:[`item.payment_type`]="{ item }">
        {{item.payment_type == 1 ? "เงินสด" : ""}}
        {{item.payment_type == 2 ? "โอน" : ""}}
        {{item.payment_type == 3 ? "บัตรเครดิต" : ""}}
      </template>
      <template v-slot:[`item.payment_date`]="{ item }">
        {{item.payment_date}}
      </template>


      <template v-slot:footer>
        <table-pagination-custom 
          :page="filter.page" 
          :itemsPerPage="filter.item_per_page" 
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";

import * as moment from "moment";

export default {
  components: {
    DatePicker
  },
  data: () => ({
    search: '',
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    list: [],
    dealer_list: [],
    headers: [
      { text: 'วันที่', value: 'date_of_issue'},
      { text: 'เลขที่เอกสาร', value: 'number', filterable: false },
      { text: 'ชื่อผู้จำหน่าย', value: 'dealer_name'},
      { text: 'ชื่อโปรเจ็ค', value: 'project_name', filterable: false},
      { text: 'มูลค่า', value: 'price', filterable: false },
      { text: 'ภาษีมูลค่าเพิ่ม', value: 'vat_price', filterable: false },
      { text: 'ยอดรวมสุทธิ', value: 'net_price', filterable: false },
      { text: 'ช่องทาง', value: 'payment_type', filterable: false },
      { text: 'ธนาคาร', value: 'payment_reference', filterable: false },
      { text: 'วันที่ชำระ', value: 'payment_date', filterable: false },
    ],
    filter: {
      branch_id: null,
      dealer_name: '',
      page: 1,
      item_per_page: 10,
      start_date: moment().startOf('month').set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss"),
      end_date: moment().endOf('month').set({hour:23,minute:59,second:59}).format("YYYY-MM-DDTHH:mm:ss"),
      //month: '',
    },
    payment_type: [
      { text: "ช่องทางการชำระ", value: "" },
      { text: "เงินสด", value: 1 },
      { text: "โอน", value: 2 },
      { text: "บัตรเครดิต", value: 3 },
    ],
    timeRangeType: 0,
    timeRangeTypes: [
      { value: 0, text: "เดือนปัจจุบัน"},
      { value: 1, text: "เลือกช่วงวันที่"},
      { value: 2, text: "เดือนก่อน"},
      { value: 3, text: "วันนี้"},
    ],
    menu: false,
    month: null,
  }),

  created () {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getDealerName()
    this.getExpenseData()
  },

  methods: {
    async getDealerName () {
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/report/get_dealer_name_list`, body)
        .then(async (res) => {
          this.dealer_list = res.result
        });
    },
    async getExpenseData () {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/report/report_by_expense`, body)
        .then((res) =>{
          this.list = res.result.data;
          this.list.forEach((item, i) => {
            this.list[i].date_of_issue = this.formatDate(moment(item.date_of_issue, "YYYY-MM-DDTHH:mm:ss"));
            this.list[i].payment_date = this.formatDate(moment(item.payment_date, "YYYY-MM-DDTHH:mm:ss"));
          });
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err',err);
          this.$alertServerError({ title : err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page){
      this.filter.page = page
      this.getExpenseData()
    },
    onChangeitemsPerPage(number){
      this.filter.item_per_page = number
      this.onChangePage(1)
    },
    async download_excel(){
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/report/download_expense_report`,
        responseType: 'blob',
        data: body
      }).then((response) => {
        if(moment(this.filter.start_date).format("DDMMYYYY")!=moment(this.filter.end_date).format("DDMMYYYY")){
          this.forceFileDownload(response, 
              'ยอดใช้จ่าย_'+
              moment(this.filter.start_date, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY")+
              '_'+
              moment(this.filter.end_date, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY")+
              '.xlsx'
          )
        }else{
          this.forceFileDownload(response, 
              'ยอดใช้จ่าย_'+
              moment(this.filter.start_date, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY")+
              '.xlsx'
          )
        }
      })
      .catch((err) => {
        console.log("err", err);
        this.$alertServerError({ title : err.error_message });
      })
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    formatDate(date) {
      if (!date) return "";
      let year = parseInt(moment(date).format( "YYYY" ))
      let m_d = moment(date).format("DD/MM")
      return `${m_d}/${year+543}`
    },
    onSaveMonth(date){
      this.$refs.menu.save(date);
      this.filter.start_date = moment(date, "YYYY-MM").set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss");
      this.filter.end_date = moment(date, "YYYY-MM").endOf('month').set({hour:23,minute:59,second:59}).format("YYYY-MM-DDTHH:mm:ss");
    },
    getMonth(date){
      return moment(date).format("YYYY-MM");
    },
  },
    
  watch: {
    timeRangeType: function(value, oldValue){
      if(value == 0){
        // 0 = เดือนปัจจุบัน
        this.filter.start_date = moment().startOf('month').set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss");
        this.filter.end_date = moment().endOf('month').set({hour:23,minute:59,second:59}).format("YYYY-MM-DDTHH:mm:ss");
      } else if(value == 1){
        // 1 = เลือกช่วงวันที่
        this.filter.start_date = moment().startOf('month').set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss");
        this.filter.end_date = moment().endOf('month').set({hour:23,minute:59,second:59}).format("YYYY-MM-DDTHH:mm:ss");
      } else if(value == 2){
        // 2 = เดือนก่อน
        this.filter.start_date = moment().subtract(1, 'months').startOf('month').set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss");
        this.filter.end_date = moment().subtract(1, 'months').endOf('month').set({hour:23,minute:59,second:59}).format("YYYY-MM-DDTHH:mm:ss");
        this.month = moment().subtract(1, 'months').format("YYYY-MM");
      } else if(value == 3){
        // 3 = วันนี้
        this.filter.start_date = moment().set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss");
        this.filter.end_date = moment().set({hour:23,minute:59,second:59}).format("YYYY-MM-DDTHH:mm:ss");
      }
    }
  }
}
</script>